.carousel {
    background-color: rgb(241, 241, 241);
    box-shadow: 0 0 10px rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 10%;
}

.container-carousel {
    width: 80%;
}

#carouselExampleIndicators {
    height: 100%;
    padding: 0;
}

@media screen and (min-width: 751px) {
    .carousel {
        display: none;
    }
}