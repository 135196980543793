* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #f3f3f3;
}

a {
    color: unset;
    text-decoration: none;
}

.hidden {
    display: none
}



::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


@keyframes slide-right {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}
